// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rl3aTHgY_"];

const serializationHash = "framer-mKwM6"

const variantClassNames = {rl3aTHgY_: "framer-v-1404bys"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, hover, id, width, ...props}) => { return {...props, kE6L5KnQB: hover ?? props.kE6L5KnQB} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, kE6L5KnQB, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rl3aTHgY_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter7dwkox = activeVariantCallback(async (...args) => {
if (kE6L5KnQB) {
const res = await kE6L5KnQB(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 632.222238970392, intrinsicWidth: 848.8889113767653, pixelHeight: 1138, pixelWidth: 1528, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/GdH4yu7TNJtJGXW00qaBVqir3Jg.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/GdH4yu7TNJtJGXW00qaBVqir3Jg.png?scale-down-to=512 512w,https://framerusercontent.com/images/GdH4yu7TNJtJGXW00qaBVqir3Jg.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/GdH4yu7TNJtJGXW00qaBVqir3Jg.png 1528w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1404bys", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"rl3aTHgY_"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter7dwkox} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mKwM6.framer-awts50, .framer-mKwM6 .framer-awts50 { display: block; }", ".framer-mKwM6.framer-1404bys { height: 1030px; overflow: visible; position: relative; width: 1383px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1030
 * @framerIntrinsicWidth 1383
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"kE6L5KnQB":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramermnOmkmsjA: React.ComponentType<Props> = withCSS(Component, css, "framer-mKwM6") as typeof Component;
export default FramermnOmkmsjA;

FramermnOmkmsjA.displayName = "logos";

FramermnOmkmsjA.defaultProps = {height: 1030, width: 1383};

addPropertyControls(FramermnOmkmsjA, {kE6L5KnQB: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramermnOmkmsjA, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})